import React, { Component } from "react";
import "./App.css";
import { Modal } from "react-bootstrap";
const appleStore =
  "https://apps.apple.com/us/app/universal-music-artists/id1476850673";
const androidStore =
  "https://play.google.com/store/apps/details?id=com.umusic.umastore";

class App extends Component {
  constructor(props) {
    super(props);
    document.title = "Universal Music Artists";
    this.state = {
      currPos: 0,
      showHide: false,
      isAuthenticated: false,
      showModal: false,
      showHowModal: false
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.showContact);
  }

  showContact = () => {
    this.setState({
      currPos: window.pageYOffset
    });
    if (this.state.currPos > 600) {
      this.setState({
        showHide: true
      });
    } else {
      this.setState({
        showHide: false
      });
    }
  };

  showVideoModal = () => {
    this.setState({
      showModal: true
    });
  };

  hideVideoModal = () => {
    this.setState({
      showModal: false
    });
  };

  showHowVideoModal = () => {
    this.setState({
      showHowModal: true
    });
  };

  hideHowVideoModal = () => {
    this.setState({
      showHowModal: false
    });
  };

  renderVideoModal = () => {
    return (
      <Modal
        show={this.state.showModal}
        onHide={this.hideVideoModal}
        aria-labelledby="videos-modal"
        dialogClassName="videos-dialog"
        backdropClassName="videos-backdrop"
        animation={true}
      >
        <Modal.Body>
          <div className="sizzle-video">
            <iframe
              className="sizzle-video--iframe"
              title="UMA Video"
              src="https://player.vimeo.com/video/373019307?color=ff8ea2&title=false&byline=false&portrait=false"
              allowFullScreen
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  renderHowVideoModal = () => {
    return (
      <Modal
        show={this.state.showHowModal}
        onHide={this.hideHowVideoModal}
        aria-labelledby="videos-modal"
        dialogClassName="videos-dialog"
        backdropClassName="videos-backdrop"
        animation={true}
      >
        <Modal.Body>
          <div className="sizzle-video">
            <iframe
              className="sizzle-video--iframe"
              title="UMA Video"
              src="https://player.vimeo.com/video/374018012?color=ff8ea2&title=false&byline=false&portrait=false"
              allowFullScreen
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.showContact);
  }

  render() {
    return (
      <div
        className="container-fluid"
        style={{
          height: "100%"
        }}
      >
        <div className="row header-section">
          <div className="section-wrapper">
            <div className="col-12">
              <div className="header-name">
                <div
                  style={{
                    marginLeft: "5px"
                  }}
                >
                  <img
                    width="16"
                    height="16"
                    src="./rectangle_logo.svg"
                    alt="logo"
                  />
                  <br />
                </div>
                Universal
                <br />
                Music
                <br />
                Artists
                <br />
              </div>
              <div className="header-sub">A more connected universe</div>
              {this.renderVideoModal()}
              {this.renderHowVideoModal()}
              <div style={{ width: "100%" }}>
                {/* <div
                  onClick={this.showVideoModal}
                  className="learn-more-button"
                  style={{ display: "inline-block" }}
                >
                  <div style={{ display: "inline-block" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      fill="#ffffff"
                      width="25px"
                      style={{ marginBottom: "2px" }}
                    >
                      <path d="M450.6 153.6c-3.3 0-6.5.9-9.3 2.7l-86.5 54.6c-2.5 1.6-4 4.3-4 7.2v76c0 2.9 1.5 5.6 4 7.2l86.5 54.6c2.8 1.7 6 2.7 9.3 2.7h20.8c4.8 0 8.6-3.8 8.6-8.5v-188c0-4.7-3.9-8.5-8.6-8.5h-20.8zM273.5 384h-190C55.2 384 32 360.8 32 332.6V179.4c0-28.3 23.2-51.4 51.4-51.4h190c28.3 0 51.4 23.2 51.4 51.4v153.1c.1 28.3-23 51.5-51.3 51.5z" />
                    </svg>
                  </div>
                  <div className="learn-more-text">&nbsp;Intro</div>
                </div> */}
                <div
                  onClick={this.showHowVideoModal}
                  className="learn-more-button"
                  style={{ display: "inline-block" }}
                >
                  <div style={{ display: "inline-block" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      fill="#ffffff"
                      width="25px"
                      style={{ marginBottom: "2px" }}
                    >
                      <path d="M376 186h-20v-40c0-55-45-100-100-100S156 91 156 146h37.998c0-34.004 28.003-62.002 62.002-62.002 34.004 0 62.002 27.998 62.002 62.002H318v40H136c-22.002 0-40 17.998-40 40v200c0 22.002 17.998 40 40 40h240c22.002 0 40-17.998 40-40V226c0-22.002-17.998-40-40-40zM256 368c-22.002 0-40-17.998-40-40s17.998-40 40-40 40 17.998 40 40-17.998 40-40 40z" />
                    </svg>
                  </div>
                  <div className="learn-more-text">&nbsp;Access</div>
                </div>
              </div>
              <div
                className="download-now"
                style={{ opacity: !this.state.showHide ? 0 : 1 }}
              >
                <a
                  rel="noopener noreferrer"
                  href={appleStore}
                  target="_blank"
                  style={{ display: this.state.showHide ? "" : "none" }}
                >
                  <img
                    alt="ios-store"
                    src="https://usaws09-umx-cdn-dev.s3.amazonaws.com/img/ios-store.svg"
                    className="ios-store-badge"
                  />
                </a>
                <a
                  rel="noopener noreferrer"
                  href={androidStore}
                  target="_blank"
                  style={{ display: this.state.showHide ? "" : "none" }}
                >
                  <img
                    alt="android-store"
                    src="https://usaws09-umx-cdn-dev.s3.amazonaws.com/img/android-store.svg"
                    className="android-store-badge"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row section-a">
          <div className="col-12 text-center video-1">
            <div className="video-phone">
              <img
                src="./phone_shell_shadow.png"
                alt="logo"
                className="mobile-fluid img-mobile"
              />
              <video
                id="home-animation-video"
                autoPlay
                muted
                loop
                poster="https://www.universalmusicartists.com/uma_home_mendes_poster.png"
                src="https://www.universalmusicartists.com/uma_home_mendes.mp4"
              ></video>
            </div>
          </div>
          <div className="col-12 text-left article-section">
            <div className="col-xs-12 col-md-6 article-title">
              Know your audience
            </div>
            <div className="col-xs-12 col-md-6 article-sub-title">
              In today’s world, your fans are everything
            </div>
            <div className="col-xs-12 col-md-6 article-content">
              Universal Music Artists helps you better understand your fans and
              what they’re paying attention to. By appreciating the size and
              make-up of your audience, either by platform or locality, you and
              your team can make the right decisions, and continually grow your
              fan-base.
            </div>
          </div>
        </div>
        <div className="row section-c">
          <div className="col-12 text-left article-section-c">
            <div className="col-xs-12 col-md-6 article-title-c">
              Insights that move you
            </div>
            <div className="col-xs-12 col-md-6 article-sub-title-c">
              Information you can use
            </div>
            <div className="col-xs-12 col-md-6 article-content-c">
              Universal Music Artists gives you actionable insights to move your
              career forward, plan tours and build set-lists. Use the app to see
              how your tracks are performing on playlists, and understand which
              playlists you should be on, and more.
            </div>
          </div>
          <div className="col-12 text-center video-2 padding-reset">
            <div className="video-computer">
              <img
                src="./computer_shell_shadow.png"
                alt="logo"
                className="img-fluid"
              />
            </div>
            <video
              id="home-animation-video-2"
              autoPlay
              muted
              loop
              poster="https://www.universalmusicartists.com/uma_social_mendes_poster.png"
              src="https://www.universalmusicartists.com/uma_social_mendes.mp4"
            ></video>
          </div>
        </div>
        <div className="row section-d"></div>
        <div className="row section-e">
          <div className="col-12 text-left article-section">
            <div className="col-xs-12 col-md-6 article-title">
              Global Coverage
            </div>
            <div className="col-xs-12 col-md-6 article-sub-title">
              The whole world in your hand
            </div>
            <div className="col-xs-12 col-md-6 article-content">
              Universal Music Artists provides data from all major streaming
              services and social media platforms around the world, for the
              first time giving you the complete picture of your music
              consumption and engagement. All in one place.
            </div>
          </div>
          <div className="col-12 text-center video-3">
            <div className="video-phone">
              <img
                src="./phone_shell_shadow.png"
                alt="logo"
                className="mobile-fluid img-mobile"
              />
              <video
                id="home-animation-video"
                autoPlay
                muted
                loop
                poster="https://www.universalmusicartists.com/poster-Music.png"
                src="https://www.universalmusicartists.com/music_phone.mp4"
                className="home-animation-video-3"
              ></video>
            </div>
          </div>
        </div>
        <div className="row section-e-padding"></div>
        <div className="row section-f">
          <div className="col-12 text-center article-section-f">
            <div className="col-xs-12 col-md-6 article-title-f mx-auto">
              From the world’s largest record label
            </div>
            <div className="col-xs-12 col-md-12 article-sub-title-f">
              Bigger is better
            </div>
            <div className="col-xs-12 col-md-6 article-content-f mx-auto">
              Universal’s volume of data across multiple partners provides you
              with unparalleled, global, market-wide context and insight unlike
              any other app.
            </div>
          </div>
        </div>
        <div className="row section-g">
          <div className="col-xs-12 col-md-6 uma-logo--container">
            <img alt="uma-logo" src="/uma-logo.png" className="img-fluid uma-logo" />
          </div>
          <div className="col-xs-12 col-md-6 umg-logo--container">
            <img alt="umg-logo" src="/umg-logo.png" className="img-fluid umg-logo" />
          </div>
        </div>
      </div>
    );
  }
}

// { this.state.showHide &&
//   <a href="mailto:uma@umusic.com">
//     <div
//       className="contact-button"
//     >
//       Contact Us
//     </div>
// </a> }

export default App;
